exports.parasNhieDo = [
   {
      name: "Cài đặt cảnh báo nhiệt độ",
      paras: [
         {
            paraName: 'PARA~REAL~warningTempLow',
            displayName: 'Cảnh báo nhiệt độ thấp',
            value: -999,
            unit: '°C',
         },
         {
            paraName: 'PARA~REAL~errorTemp',
            displayName: 'Cảnh báo nhiệt độ cao',
            value: -999.99,
            unit: '°C',
         },
      ]
   }
]

exports.parasBienTan = [
   {
      name: "Cài đặt cảnh báo biến tần",
      paras: [
         {
            paraName: 'PARA~REAL~warningFrequency1Low',
            displayName: 'Bt thải sấy: cảnh báo tốc độ thấp',
            value: -999.99,
            unit: 'Hz',
         },
         {
            paraName: 'PARA~REAL~warningFrequency3Low',
            displayName: 'Bt thu hồi: cảnh báo tốc độ thấp',
            value: -999.99,
            unit: 'Hz',
         },
         {
            paraName: 'PARA~REAL~warningFrequency2Low',
            displayName: 'Bt thải nung: cảnh báo tốc độ thấp',
            value: -999.99,
            unit: 'Hz',
         },
         {
            paraName: 'PARA~REAL~warningFrequency4Low',
            displayName: 'Quạt cửa lò: cảnh báo tốc độ thấp',
            value: -999.99,
            unit: 'Hz',
         },
      ]
   }
]