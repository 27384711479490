exports.widgets = [
   {
      widget_type: 'point-widget',
      reportName: 'temp01',
      displayName: 'Can nhiệt 1',
      icon: 'mdi-thermometer',
      value: 0,
      color: '#F44336',
      unit: '°C',
      mobileCols: 4,
      laptopCols: 2,
      width: "14.285%",
      valueFontSize: "20px",
      valueFontSizeMobile: "22px",
   },
   {
      widget_type: 'point-widget',
      reportName: 'temp02',
      displayName: 'Can nhiệt 2',
      icon: 'mdi-thermometer',
      value: 0,
      color: '#D500F9',
      unit: '°C',
      mobileCols: 4,
      laptopCols: 2,
      width: "14.285%",
      valueFontSize: "20px",
      valueFontSizeMobile: "22px",
   },
   {
      widget_type: 'point-widget',
      reportName: 'temp03',
      displayName: 'Can nhiệt 3',
      icon: 'mdi-thermometer',
      value: 0,
      color: '#00BCD4',
      unit: '°C',
      mobileCols: 4,
      laptopCols: 2,
      width: "14.285%",
      valueFontSize: "20px",
      valueFontSizeMobile: "22px",
   },
   {
      widget_type: 'point-widget',
      reportName: 'temp04',
      displayName: 'Can nhiệt 4',
      icon: 'mdi-thermometer',
      value: 0,
      color: '#3F51B5',
      unit: '°C',
      mobileCols: 4,
      laptopCols: 2,
      width: "14.285%",
      valueFontSize: "20px",
      valueFontSizeMobile: "22px",
   },
   {
      widget_type: 'point-widget',
      reportName: 'temp05',
      displayName: 'Can nhiệt 5',
      icon: 'mdi-thermometer',
      value: 0,
      color: '#CDDC39',
      unit: '°C',
      mobileCols: 4,
      laptopCols: 2,
      width: "14.285%",
      valueFontSize: "20px",
      valueFontSizeMobile: "22px",
   },
   {
      widget_type: 'point-widget',
      reportName: 'temp06',
      displayName: 'Can nhiệt 6',
      icon: 'mdi-thermometer',
      value: 0,
      color: '#4CAF50',
      unit: '°C',
      mobileCols: 4,
      laptopCols: 2,
      width: "14.285%",
      valueFontSize: "20px",
      valueFontSizeMobile: "22px",
   },
   {
      widget_type: 'point-widget',
      reportName: 'temp07',
      displayName: 'Khí nóng',
      icon: 'mdi-thermometer',
      value: 0,
      color: '#FF9800',
      unit: '°C',
      mobileCols: 4,
      laptopCols: 2,
      width: "14.285%",
      valueFontSize: "20px",
      valueFontSizeMobile: "22px",
   },
   { widget_type: 'newline-widget', height: "4px" },
   {
      widget_type: 'chart-widget',
      title: 'Lịch sử nhiệt độ',
      chartType: 'line',
      maxValue: 1200,
      points: [
         {
            reportName: 'temp01', name: 'Can nhiệt 1', active: true,
            scale: 1, unit: '°C', color: "#F44336", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'temp02', name: 'Can nhiệt 2', active: true,
            scale: 1, unit: '°C', color: "#D500F9", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'temp03', name: 'Can nhiệt 3', active: true,
            scale: 1, unit: '°C', color: "#00BCD4", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'temp04', name: 'Can nhiệt 4', active: true,
            scale: 1, unit: '°C', color: "#3F51B5", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'temp05', name: 'Can nhiệt 5', active: true,
            scale: 1, unit: '°C', color: "#CDDC39", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'temp06', name: 'Khí nóng', active: true,
            scale: 1, unit: '°C', color: "#4CAF50", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'temp07', name: 'Khí ẩm', active: true,
            scale: 1, unit: '°C', color: "#FF9800", backgroundColor: "#FF000000", 
         },
      ], 
      mobileCols: 12,
      laptopCols: 12,
      dontBeginAtZero: true,
      minHeight: "544px",
   },

]

exports.style = {
   backgroundImg: 'url("/imgs/bg0.jpg")',
   backgroundColor: '#AAAAAA',
   transparentColor: '#00000033',
   blurBackground: true,
}