import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import axios from "axios"
import VueAxios from "vue-axios"
import VueSocketIO from 'vue-socket.io'
import VueChart from 'vue-chart-js'
import VueTouchKeyboard from "vue-touch-keyboard"
import isElectron from 'is-electron'
import { setupComponents } from "./config/setup-components"
// eslint-disable-next-line
import style from "vue-touch-keyboard/dist/vue-touch-keyboard.css"

Vue.use(VueTouchKeyboard)

Vue.config.productionTip = false

setupComponents(Vue)

// --------- Find out where u run me -----------
var apiHostname = window.location.hostname
var isRemote = false
var requireLogin = true
apiHostname = 'http://' + apiHostname
let forceRemote = true

if(apiHostname.includes('localhost')){
  apiHostname = 'http://localhost'
  isRemote = false
}

if(apiHostname.includes('pono.vn') || forceRemote){
  apiHostname = 'http://localhost'
  isRemote = true
}

if(isElectron()) {
  apiHostname = 'http://localhost'
  isRemote = false
}

// FORCE --------------------------------
// apiHostname = 'http://192.168.1.106'
// apiHostname = 'http://192.168.9.109'
apiHostname = 'http://localhost'
isRemote = true
// END FORCE ----------------------------

console.log("Hostname " + apiHostname)
console.log("IsRemote " + isRemote)

// --------------------------------------------------------

let serverHostSocket = isRemote?"https://otaio1.pono.vn":`${apiHostname}:3055`
Vue.use(VueSocketIO, serverHostSocket)
Vue.use(VueChart)

Vue.use(VueAxios, axios)
Vue.use(require('vue-moment'))

const EventBus = new Vue()
Vue.prototype.$eventBus = EventBus

if (requireLogin) {
  router.beforeEach((to, from, next)=>{
    store.dispatch('fetchUserId')
    if (to.fullPath !== '/login' && (!store.state.userId) ) { //|| !store.state.token
      next('/login')
    } else {
      next()
    }
  })
}

new Vue({
  vuetify,
  router,
  store,
  data: {
    devCode: "PN-LOGACH-02",  // PN-LOGACH-ISO01
    HOSTNAME: apiHostname,
    isRemote: isRemote,
    virtualKeyboard: false,
    isElectron: isElectron(),
    bottomNavigate: 'tab',  // tab , goDownOnly
    apiGate: apiHostname + ':3055/',
    logApi: "http://localhost:3044/",
    lanLogApi: apiHostname + ':3044/',

    // apiUser: "http://localhost:3000",
    // apiUserCustomerId: "logach-danang-01",

    apiUser: "https://saasgusr.pono.vn/api",
    apiUserCustomerId: "logach-danang-01",

    parasKeys: "ujihertybfg4fet56ythf433",
  },
  render: h => h(App),
}).$mount('#app')
